@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

$reset-margin: 0;

.verticalRule {
  align-self: center;
  background-color: $primary-color;
  height: 100%;
  justify-self: center;
  width: 2px;
}

.container {
  column-gap: rem-calc(16px);
  display: grid;
  grid-template-areas:
    'icon title'
    'vertical-rule text';
  grid-template-columns: rem-calc(20px) 1fr;

  &:last-child .verticalRule {
    visibility: hidden;
  }
}

.icon {
  align-self: center;
}

.title {
  align-items: center;
  display: grid;
  font-size: rem-calc(16px);
  font-weight: 600;
  margin: $reset-margin;
}

.hidden {
  visibility: hidden;
}

.text {
  align-items: start;
  color: $neutrals-gray6;
  font-size: rem-calc(14px);
  font-weight: 400;
  line-height: rem-calc(20px);
  margin: $reset-margin;
}

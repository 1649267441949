@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

.container {
  margin-top: rem-calc(56px);
  margin-left: auto;
  margin-right: auto;
  font-size: rem-calc(16px);

  @include device-sm-or-smaller {
    margin-top: rem-calc(24px);
  }
}

.subtitle {
  max-width: 572px;
}

@mixin overlay($bgColor) {
  position: relative;
  z-index: 0;

  &:after {
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    background-color: $bgColor;
  }
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

.paper {
  width: 100%;

  @include device-md-or-larger {
    width: 45%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: rem-calc(16px);
  margin: rem-calc(8px) 0 rem-calc(24px);
}

.logo {
  height: rem-calc(56px);
}

@mixin navigation-link() {
  color: $neutrals-gray6;
  font-size: rem-calc(14px);

  &:hover {
    opacity: 0.9;
  }
}

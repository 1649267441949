@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;

.container {
  display: grid;
  row-gap: rem-calc(4px);
  text-align: center;
}

.quote {
  color: $neutrals-gray6;
  font-size: rem-calc(14px);
  font-weight: 400;
  line-height: rem-calc(20px);
  margin: $reset-margin;
}

@include device-lg-or-larger {
  .quote {
    font-size: rem-calc(16px);
  }
}

.author {
  color: $neutrals-gray5;
  font-size: rem-calc(12px);
  justify-self: center;
  font-style: normal;

  a {
    text-decoration: none;
  }
}

@import '../utils/rem-calc';
@import '../variables';

html,
body,
#root {
  min-height: 100vh;
}

// Keeps loading and 401 unathorized centered
#root {
  display: grid;
  place-items: center;
}

.co-browser-view-only {
  display: none;
}

[upscope-co-browse='true'] {
  pointer-events: none;

  &[data-upscope-uid] {
    pointer-events: unset;
  }

  .editable {
    display: none;
  }

  .editable[data-upscope-uid] {
    display: unset;
  }

  .co-browser-view-only:not([data-upscope-uid]) {
    display: unset;
  }
}

html {
  @include rem-base;
}

body {
  color: $font-color;
  font-family: $font-family;
  background-color: $body-background;

  // allow colors to be printed
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';

.root {
  flex: 1;
  max-width: rem-calc(375px);

  .header {
    color: #213f7e;
    font-weight: 600;
    line-height: rem-calc(20px);
    padding: rem-calc(16px) rem-calc(6px) rem-calc(16px) 0;
    // box-sizing: content-box;
    border-bottom: 1px solid $neutrals-gray3;
  }

  .lastColumn {
    padding: rem-calc(16px) 0 rem-calc(16px) rem-calc(6px);
  }
}

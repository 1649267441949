@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

.text {
  ul {
    list-style-type: none;
    padding-inline-start: 0;

    li {
      margin: rem-calc(20px) auto;
    }
  }

  align-items: start;
  display: grid;
  justify-items: center;
  row-gap: rem-calc(50px);
}

.alignCenter {
  text-align: center;
}

@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

.container {
  justify-self: center;
  margin: rem-calc(24px) 0;
  max-width: calc(100vw - #{rem-calc(96px)});

  @include device-xs {
    margin: rem-calc(24px) 0;
  }

  @include device-sm-or-larger {
    margin: rem-calc(88px) 0;
    max-width: calc(100vw - #{rem-calc(144px)});
  }

  @include device-lg-or-larger {
    max-width: rem-calc(770px);
  }
}

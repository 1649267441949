@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.bar {
  border-radius: rem-calc(5px);
  background-color: $font-color;

  &.overutilized {
    background-color: $tertiary-red-dark;
  }
}

.root {
  height: rem-calc(4px);
  border-radius: rem-calc(5px);
  background-color: $neutrals-gray2;
}

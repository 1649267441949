@import '../variables';

// Extra small devices or smaller
@mixin device-xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

// Alias for small devices or smaller
@mixin device-xs-or-smaller {
  @include device-xs;
}

// Focus on only small devices
@mixin device-sm {
  @media (min-width: #{$screen-xs + 1px}) and (max-width: #{$screen-sm}) {
    @content;
  }
}

// Small devices and down
@mixin device-sm-or-smaller {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Small devices and up
@mixin device-sm-or-larger {
  @media (min-width: #{$screen-xs + 1px}) {
    @content;
  }
}

// Focus on medium devices only
@mixin device-md {
  @media (min-width: #{$screen-sm + 1px}) and (max-width: #{$screen-md}) {
    @content;
  }
}

// Medium devices and down
@mixin device-md-or-smaller {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Medium devices and up
@mixin device-md-or-larger {
  @media (min-width: #{$screen-sm + 1px}) {
    @content;
  }
}

// Focus on large devices only
@mixin device-lg {
  @media (min-width: #{$screen-md + 1px}) and (max-width: #{$screen-lg}) {
    @content;
  }
}

// Large devices and smaller
@mixin device-lg-or-smaller {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

// Large devices and larger
@mixin device-lg-or-larger {
  @media (min-width: #{$screen-md + 1px}) {
    @content;
  }
}

// Focus on extra large devices only
@mixin device-xl {
  @media (min-width: #{$screen-lg + 1px}) {
    @content;
  }
}

// Extra large devices and up
@mixin device-xl-or-larger {
  @include device-xl;
}

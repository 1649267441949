@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;
$reset-padding: 0;

.trustPilotReview {
  .reviews {
    padding-top: rem-calc(16px);

    a {
      text-decoration: none;
    }
  }

  blockquote {
    color: #333;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
    margin-top: rem-calc(24px);
    margin-bottom: rem-calc(4px);
  }

  p {
    margin: 0;
  }

  blockquote + p {
    color: #6e6e6e;
    font-size: 0.75rem;
    justify-self: center;
    font-style: normal;
  }

  max-width: 21.75rem;
  text-align: center;
}

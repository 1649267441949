@import 'styles/scss/variables';
@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

$header-height: rem-calc(56px);

.container {
  display: grid;
  column-gap: rem-calc(24px);
  grid-auto-flow: column;
  height: $header-height;
}

.link {
  align-items: end;
  color: $neutrals-gray6;
  display: grid;
  font-size: rem-calc(16px);
  line-height: rem-calc(16px);
  padding: rem-calc(8px) rem-calc(16px);
  text-decoration: none;
  white-space: nowrap;

  &:focus,
  &:visited {
    text-decoration: none;
  }
}

.dark {
  color: $neutrals-pure-white;
}

@import 'styles/scss/utils/responsive';
@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.container {
  @include device-md-or-smaller {
    max-width: rem-calc(576px);
  }

  @include device-lg-or-larger {
    max-width: rem-calc(704px);
  }
}

.content {
  display: grid;
  row-gap: rem-calc(24px);
  margin: rem-calc(24px) rem-calc(0.5px);

  @include device-md {
    row-gap: rem-calc(32px);
    margin-top: rem-calc(56px);
  }

  @include device-lg-or-larger {
    row-gap: rem-calc(40px);
    margin-top: rem-calc(56px);
  }
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.container {
  color: $neutrals-gray5;
  display: inline;
  font-size: rem-calc(12px);
  line-height: 1.33;
  margin: 0;
  padding: 0;

  ol {
    display: grid;
    padding: 0 2em;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    margin: 0;
  }
}

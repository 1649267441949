@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;

.container {
  align-items: start;
  display: grid;
  justify-items: center;
}

.topSpacer {
  height: rem-calc(40px);

  @include device-md-or-larger {
    height: rem-calc(56px);
  }
}

.middleSpacer {
  height: rem-calc(24px);

  @include device-md-or-larger {
    height: rem-calc(56px);
  }

  @include device-lg-or-larger {
    height: rem-calc(40px);
  }
}

.breakdown {
  display: grid;
  row-gap: rem-calc(8px);

  @include device-sm-or-larger {
    column-gap: rem-calc(9px);
    grid-template-columns: 2fr auto 3fr;
    grid-auto-flow: column;
    row-gap: 0;
  }

  @include device-md-or-larger {
    column-gap: rem-calc(18px);
  }

  @include device-lg-or-larger {
    column-gap: rem-calc(36px);
  }
}

.paymentSummary {
  display: grid;
  justify-items: center;
  row-gap: rem-calc(8px);
}

.lowestMonthlyPayment {
  color: $tertiary-green-dark;
  font-size: rem-calc(12px);
  margin: $reset-margin;
  text-align: center;
  text-transform: uppercase;

  @include device-lg-or-larger {
    font-size: rem-calc(14px);
  }
}

.estimatedMonthlyPayment {
  font-size: rem-calc(64px);
  font-weight: 600;
  line-height: 1;
  margin: $reset-margin;
}

.unit {
  font-size: rem-calc(14px);
}

.splitter {
  background-color: $neutrals-gray3;
  height: rem-calc(1px);

  @include device-sm-or-larger {
    height: 100%;
    width: rem-calc(1px);
  }
}

.schedule {
  align-content: space-around;
  column-gap: rem-calc(8px);
  display: grid;
  font-size: rem-calc(12px);
  grid-template-columns: 1fr auto;
  row-gap: rem-calc(8px);

  @include device-md-or-larger {
    font-size: rem-calc(14px);
  }

  @include device-lg-or-larger {
    font-size: rem-calc(16px);
  }
}

.scheduleLabel {
  color: $neutrals-gray5;
}

.scheduleValue {
  font-weight: 600;
  text-align: end;
}

.bottomSpacer {
  height: rem-calc(24px);

  @include device-md-or-larger {
    height: rem-calc(56px);
  }

  @include device-lg-or-larger {
    height: rem-calc(40px);
  }
}

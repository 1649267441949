@import 'styles/scss/utils/responsive';
@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

$reset-margin: 0;
$reset-padding: 0;

.container {
  display: grid;
  justify-items: center;

  @include device-xs {
    row-gap: rem-calc(24px);
  }
}

.subtitle {
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  margin-top: rem-calc(10px);
  max-width: rem-calc(396px);
  text-align: center;

  @include device-lg-or-larger {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
    max-width: rem-calc(770px);
  }
}

.content {
  margin-top: rem-calc(56px);
  display: grid;
  column-gap: rem-calc(18px);

  @include device-md {
    max-width: rem-calc(348px);
  }

  @include device-lg-or-larger {
    row-gap: rem-calc(24px);
    grid-auto-flow: column;
  }
}

.itemContainer {
  margin: $reset-margin;
  padding: $reset-padding;
  margin-bottom: rem-calc(24px);
}

.item {
  display: grid;
  align-items: start;
  grid-auto-flow: column;
  grid-column-gap: rem-calc(18px);
  grid-template-columns: auto 1fr;
  margin-bottom: rem-calc(24);
}

.text {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);
  color: $neutrals-gray7;
  margin-top: rem-calc(-3.5px);

  @include device-md {
    width: rem-calc(308px);
  }

  @include device-lg {
    margin-right: rem-calc(50px);
  }
}

.icon {
  align-items: center;
  display: grid;
}

.asterisk {
  margin-top: rem-calc(30px);
  color: $neutrals-gray5;
}

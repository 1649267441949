@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';
@import 'styles/scss/utils/responsive';

.container {
  align-items: baseline;
  min-height: 100vh;
  min-width: 100vw;
  display: grid;
  grid-template-rows: auto 1fr auto;

  :global(.framer-motion-div),
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
}

.withBG {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  color: $neutrals-pure-white;
  max-height: 100vh;

  @include overlay(rgba($black, 0.4));

  footer {
    position: absolute;
    top: 100vh;
  }
}

.header {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  min-height: rem-calc(56px);
}

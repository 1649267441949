@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

.container {
  justify-self: center;
  max-width: rem-calc(374px);
}

.incomeExpencesRow {
  display: flex;
  align-items: center;
  text-align: center;
  margin: rem-calc(50px) 0;

  @include device-md-or-larger {
    margin: rem-calc(75px) 0;
  }
}

.discretionaryIncomeRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: rem-calc(28px);
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);
  font-weight: 600;
}

.discretionaryIncomeRowTitle {
  font-weight: 700;
  color: $tertiary-blue-gradient-light-2;
}

// ----
// rem mixins
// ----
$rem-base: 16px !default;

@mixin rem-base($zoom: 100%) {
  font-size: $zoom / 16px * $rem-base;
}

// $base-font-size: 100% !default;
// //$base-line-height is 24px while $base-font-size is 16px
// $base-line-height: 150%;
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of em's.
// Call with 1 parameter, 'px' is not required but supported
// rem-calc(10 20 30px 40);
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;

  // Turn 0em into 0
  @if ($value==0rem) {
    $value: 0;
  }

  @return $value;
}

@function rem-calc($value, $base-value: $rem-base) {
  @return convert-to-rem($value, $base-value);
}

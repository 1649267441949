@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

.container {
  margin-top: rem-calc(42px);
  margin-left: auto;
  margin-right: auto;
  font-size: rem-calc(16px);
  align-items: start;
  display: grid;
  justify-items: center;
  row-gap: rem-calc(40px);
  grid-row-gap: rem-calc(40px);
}

.item {
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);
  color: $neutrals-gray7;
  margin-bottom: rem-calc(48px);

  svg {
    margin-right: rem-calc(12px);
  }
}

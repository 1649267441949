@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.container {
  align-items: center;
  column-gap: rem-calc(8px);
  display: grid;
  grid-template-areas: 'label content';
  grid-template-columns: auto 1fr;
  justify-content: start;
  min-height: rem-calc(40px);
}

.label {
  display: grid;
  font-size: rem-calc(12px);
  font-weight: 600;
  grid-area: label;
  margin: 0;
  justify-content: end;
  text-align: end;
  width: rem-calc(72px);
}

.labelHighlighted {
  color: $tertiary-green-dark;
}

.content {
  align-items: center;
  background-color: $neutrals-gray3;
  display: grid;
  grid-area: content;
  padding: rem-calc(8px);
  position: relative;
  z-index: 20;
  width: min-content;
}

.contentHighlighted {
  background-color: $tertiary-green-dark;
  color: $neutrals-pure-white;
}

.animate {
  background-color: $neutrals-gray3;
  grid-area: content;
  height: 100%;
  left: 0;
  position: relative;
  width: 0;
  transition: width 1.5s ease-in-out;
  z-index: 10;
}

.no-animate {
  background-color: $neutrals-gray3;
  grid-area: content;
  height: 100%;
  left: 0;
  position: relative;
  z-index: 10;
}

.animateHighlighted {
  background-color: $tertiary-green-dark;
}

.amount {
  font-size: rem-calc(16px);
  font-weight: 600;
  line-height: 0.9;
  white-space: nowrap;
}

.unit {
  font-size: rem-calc(12px);
  font-weight: 400;
  white-space: nowrap;
}

.check {
  bottom: 50%;
  color: $tertiary-green-dark;
  position: absolute;
  right: 0;
  transform: translate(125%, 50%);
}

.gap {
  position: absolute;
  right: 50%;
  top: rem-calc(-2px);
  transform: translateX(50%);
}

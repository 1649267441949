@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

.ieCard {
  margin: 0 rem-calc(24px);
}

.ieCardTitle {
  font-size: rem-calc(14px);
  line-height: rem-calc(16px);
  margin-bottom: rem-calc(12px);
}

.ieAmount {
  font-size: rem-calc(24px);
  line-height: rem-calc(32px);
  font-weight: 700;

  &.red {
    color: $logo-red;
  }

  &.green {
    color: $tertiary-green-light-2;
  }
}

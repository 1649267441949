@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

$stroke-width: 4.5;
$gauge-size: 100%;
$reset-margin: 0;

.container {
  display: grid;
  row-gap: rem-calc(16px);
  justify-items: center;

  svg {
    margin: $reset-margin;
  }
}

.label {
  font-size: rem-calc(12px);
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
}

.ring {
  display: grid;
  margin: 0 auto;
  max-width: 100%;
  max-height: $gauge-size;
}

.none {
  fill: $neutrals-gray4;
  font-family: sans-serif;
  font-size: 55%;
  font-weight: bold;
  text-anchor: middle;
}

.percentage {
  fill: $tertiary-red-dark;
  font-family: sans-serif;
  font-size: 55%;
  font-weight: bold;
  text-anchor: middle;
}

.subtitle {
  fill: $tertiary-red-dark;
  font-family: sans-serif;
  font-size: 30%;
  text-anchor: middle;
}

.background {
  fill: none;
  stroke: $neutrals-gray3;
  stroke-width: $stroke-width;
}

.foreground {
  animation: progress 1s ease-out;
  fill: none;
  stroke: $tertiary-red-dark;
  stroke-linecap: round;
  stroke-width: $stroke-width;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

@import '../index.module.scss';

.link {
  @include navigation-link();
}

.list {
  padding-left: rem-calc(20px);

  :global(.MuiListItemText-primary) {
    color: $neutrals-gray5;
  }
}

.arrow {
  transition: transform 0.2s ease;

  path {
    fill: $black;
  }
}

.isOpen {
  transform: rotate(90deg);
}

.isClosed {
  transform: rotate(270deg);
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.button {
  place-items: flex-end;
  background-color: transparent;
  border: none;
  color: $neutrals-gray6;
  cursor: pointer;
  display: flex;
  font-size: rem-calc(16px);
  text-transform: none;
  white-space: nowrap;
  padding-bottom: rem-calc(8px);
}

.arrowContainer {
  display: grid;
  height: rem-calc(24px);
  place-items: end;
  width: rem-calc(24px);
}

.arrow {
  display: grid;
  height: rem-calc(14px);
  margin-inline-start: rem-calc(8px);
  place-items: center;
  transform: rotate(270deg);
  width: rem-calc(14px);

  path {
    fill: $black;
  }
}

.menu {
  :global {
    .MuiList-padding {
      padding: 0;
    }
  }
}

.link {
  color: $neutrals-gray5;
  font-size: rem-calc(14px);
  max-width: rem-calc(216px);
  padding: rem-calc(12px) 0;
  text-decoration: none;
  white-space: normal;
  width: 100%;
}

.dark {
  color: $neutrals-pure-white;

  path {
    fill: $neutrals-pure-white;
  }
}

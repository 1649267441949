@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';
@import 'styles/scss/utils/responsive';

.container {
  align-items: center;
  display: grid;
  min-height: 100vh;
  min-width: 100vw;
  color: $neutrals-pure-white;
  justify-content: center;

  @include overlay(rgba($black, 0.55));

  background-repeat: no-repeat;
  background-position: 39% center;
}

.headerContainer {
  position: absolute;
  top: 0;
  width: 100vw;
}

.wrapper {
  place-items: center;
  display: grid;
  margin: rem-calc(24px) rem-calc(30px) rem-calc(100px) rem-calc(30px);

  @media screen and (max-height: 700px) {
    padding-top: rem-calc(48px);
  }
}

.heading {
  font-size: rem-calc(32px);
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 8px;

  @include device-md-or-smaller {
    font-size: rem-calc(24px);
    line-height: 32px;
  }
}

.greyColor {
  color: $neutrals-gray2;
}

.infoBlock {
  margin-top: 40px;
}

.thickText {
  display: block;
  font-size: rem-calc(32px);
  line-height: 40px;
  font-weight: 700;
  color: $neutrals-pure-white;

  @include device-md-or-smaller {
    font-size: rem-calc(24px);
    line-height: 32px;
  }
}

.infoLabel {
  color: $neutrals-gray2;
  display: block;
  font-size: rem-calc(12px);
  line-height: 16px;
}

.hero {
  padding: rem-calc(48px) 0;
}

.heroText {
  font-size: rem-calc(12px);
}

.sections {
  display: grid;
  margin: rem-calc(56px) 0;
  row-gap: rem-calc(40px);

  @include device-xs {
    margin: rem-calc(30px) 0 rem-calc(56px) 0;
  }

  @include device-sm-or-larger {
    margin: rem-calc(64px) 0;
  }
}

.headerWrapper {
  display: grid;
  row-gap: rem-calc(28px);

  @include device-lg-or-larger {
    max-width: rem-calc(489px);
  }

  > div {
    font-weight: 600;
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
    text-align: center;
    letter-spacing: 0.0024em;
    margin: 0 rem-calc(15px);
  }
}

@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

.container {
  align-items: start;
  display: grid;
  row-gap: rem-calc(24px);

  @include device-md {
    max-width: rem-calc(396px);
  }
}

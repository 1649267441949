@import 'styles/scss/utils/responsive';
@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

$reset-margin: 0;
$reset-padding: 0;

.container {
  display: grid;
  row-gap: rem-calc(56px);

  @include device-xs {
    row-gap: rem-calc(24px);
  }
}

.content {
  display: grid;
  row-gap: rem-calc(24px);

  @include device-lg-or-larger {
    row-gap: rem-calc(56px);
  }
}

.itemContainer {
  column-gap: rem-calc(24px);
  display: grid;
  margin: $reset-margin;
  padding: $reset-padding;
  row-gap: rem-calc(24px);

  @include device-lg-or-larger {
    column-gap: rem-calc(50px);
    grid-auto-flow: column;
  }
}

.item {
  align-items: start;
  display: grid;
  column-gap: rem-calc(18px);
  grid-template-columns: auto 1fr;
}

.text {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);
  color: $neutrals-gray7;

  @include device-md {
    width: rem-calc(308px);
  }
}

.icon {
  align-items: center;
  display: grid;
}

.conclusion {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);
  align-items: center;
  text-align: center;
  color: $neutrals-gray7;

  @include device-md {
    height: rem-calc(48px);
  }
}

@import "styles/scss/variables";

.alertIcon {
  color: $tertiary-red-dark;
  margin-right: rem-calc(9.47px);
}

.floatLeft {
  float: left;
}

@import 'styles/scss/utils/rem-calc';

.container {
  display: grid;
  place-items: center;
  position: relative;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
}

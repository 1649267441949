@import 'styles/scss/utils/rem-calc';

.container {
  display: grid;
  min-width: 100vw;
  min-height: 100vh;
  place-content: center;
  place-items: center;
  row-gap: rem-calc(48px);
  text-align: center;
}

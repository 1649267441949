@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

.iconContainer {
  background-color: rgba($font-color, 0.2);
  border-radius: 100%;
  color: $neutrals-pure-white;
  cursor: pointer;
  display: grid;
  height: rem-calc(24px);
  place-items: center;
  transition: background-color ease-in 0.1s;
  width: rem-calc(24px);
}

.container {
  display: grid;
  height: 33vh;
  place-items: center;
  position: fixed;
  width: rem-calc(44px);
  top: 50vh;
  transform: translateY(-50%);
  user-select: none;
  z-index: 1;

  @include device-sm-or-larger {
    width: rem-calc(64px);
  }

  &:focus,
  &:hover {
    .iconContainer {
      background-color: rgba($font-color, 0.4);
    }
  }
}

.previous {
  left: 0;
}

.next {
  right: 0;
}

.rotate180 {
  transform: rotate(180deg);
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
}

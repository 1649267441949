@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

$header-height: rem-calc(56px);

.container {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);

  &.darkMode {
    background: transparent;
    box-shadow: none;
  }
}

.inner {
  align-items: center;
  column-gap: rem-calc(32px);
  display: grid;
  grid-template-columns: auto 1fr auto;

  @include device-md-or-larger {
    grid-template-columns: auto 1fr auto;
    justify-items: start;
    margin: 0 auto;
    max-width: rem-calc(1168px);
    padding: rem-calc(8px) rem-calc(16px);
  }

  @include device-md-or-smaller {
    column-gap: 0;
    padding: 0 rem-calc(16px) 0 0;
  }

  &.minimal {
    grid-template-columns: 1fr;
  }

  &.hideMenu {
    padding-left: rem-calc(16px);
  }
}

.phone {
  display: grid;
  height: $header-height;
  place-items: center;
  justify-self: end;
  min-width: $header-height;
}

.left {
  align-items: center;
  justify-content: start;
  display: grid;
  grid-auto-flow: column;
}

.hamburger {
  align-items: center;
  background: none;
  border: 0;
  display: grid;
  height: $header-height;
  justify-items: center;
  width: $header-height;
  padding-top: 1rem;

  @include device-lg-or-larger {
    display: none;
  }
}

.logo {
  @include device-lg-or-larger {
    height: $header-height;
  }

  width: 100%;
  max-height: $header-height;
  max-width: rem-calc(91px);
}

.content {
  display: none;

  @include device-lg-or-larger {
    display: unset;
  }
}

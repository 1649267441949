@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

.phone {
  position: absolute;
  top: rem-calc(16px);
  right: rem-calc(16px);
}

.logo-container {
  margin-top: rem-calc(30px);
}

.name {
  font-size: rem-calc(32px);
  font-weight: bold;

  @include device-md-or-smaller {
    font-size: rem-calc(24px);
  }
}

.logo {
  width: rem-calc(170px);
}

.hero {
  display: grid;
  row-gap: rem-calc(4px);
}

.heroText {
  font-size: rem-calc(12px);
}

.coAppAnd {
  font-size: rem-calc(12px);
  line-height: rem-calc(12px);
  font-weight: normal;
  margin: rem-calc(4px) 0;
}

.explanation {
  display: grid;
  justify-content: center;
  row-gap: rem-calc(24px);

  @include device-md-or-smaller {
    font-size: rem-calc(14px);
  }
}

.headerContainer {
  position: absolute;
  top: 0;
  width: 100vw;
}

.content {
  display: grid;
  margin: rem-calc(0) rem-calc(44px);
  max-width: rem-calc(530px);
  row-gap: rem-calc(48px);
}

@import 'styles/scss/variables';

.white {
  :global {
    .textual,
    .sun-ray {
      fill: $neutrals-pure-white;
    }
  }
}

.redBlue {
  :global {
    .textual {
      fill: $logo-blue;
    }

    .sun-ray {
      fill: $logo-red;
    }
  }
}

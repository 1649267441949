@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;

.container {
  align-items: start;
  display: grid;
  justify-content: center;
  row-gap: rem-calc(8px);
}

.title {
  color: $primary-color;
  font-size: rem-calc(24px);
  font-weight: 700;
  margin: $reset-margin;
  text-align: center;

  p {
    margin: 0;
  }
}

@include device-lg-or-larger {
  .title {
    font-size: rem-calc(32px);
  }
}

.subtitle {
  font-size: rem-calc(14px);
  margin: $reset-margin;
  text-align: center;

  p {
    margin: 0;
  }
}

.dark {
  color: $neutrals-pure-white;
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';

.totalColumn {
  font-size: rem-calc(16px);
  font-weight: 600;
  padding: rem-calc(16px) 0 rem-calc(16px) rem-calc(6px);
}

.label {
  text-transform: uppercase;
  font-weight: 700;
  padding: rem-calc(16px) rem-calc(6px) rem-calc(16px) 0;
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';
@import 'styles/scss/utils/responsive';

.phone {
  position: absolute;
  top: rem-calc(16px);
  right: rem-calc(16px);
}

.logo-container {
  margin-top: rem-calc(30px);
}

.name {
  font-size: rem-calc(32px);
  font-weight: bold;

  @include device-md-or-smaller {
    font-size: rem-calc(24px);
  }
}

.logo {
  width: rem-calc(170px);
}

.headerContainer {
  position: absolute;
  top: 0;
  width: 100vw;
}

.content {
  display: grid;
  margin: rem-calc(0) rem-calc(44px);
  max-width: rem-calc(530px);
  row-gap: rem-calc(48px);
}

.figures {
  display: grid;
  margin: rem-calc(56px) 0;
  row-gap: rem-calc(40px);

  @include device-xs {
    margin: rem-calc(48px) 0;
  }

  @include device-sm-or-larger {
    margin: rem-calc(64px) 0;
  }
}

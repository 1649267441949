@import 'styles/scss/utils/rem-calc';

.container {
  align-items: center;
  display: grid;
  justify-items: center;
  row-gap: rem-calc(9px);
}

.rating {
  align-items: center;
  column-gap: rem-calc(8px);
  display: grid;
  grid-auto-flow: column;
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.title {
  margin: 0;
}

.toolTip {
  color: $tertiary-denim-blue;
  font-size: rem-calc(10px);
  line-height: rem-calc(16px);
}

.button {
  background-color: transparent;
  border: 0;
  color: $tertiary-denim-blue;
  cursor: pointer;
}

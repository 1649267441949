$reset-margin: 0;
$reset-padding: 0;

.container {
  place-items: center;
  display: grid;
  margin: $reset-margin;
  padding: $reset-padding;
}

.image {
  max-width: 100%;
}

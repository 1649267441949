@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;

.container {
  align-items: start;
  display: grid;
  justify-items: center;
  row-gap: rem-calc(50px);

  @include device-sm-or-larger {
    row-gap: rem-calc(56px);
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;

    li {
      margin: rem-calc(20px) auto;
    }
  }
}

.paragraph {
  margin: $reset-margin;
  text-align: center;
}

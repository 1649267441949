@import 'styles/scss/variables';
@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';

.container {
  margin: rem-calc(56px) auto 0 auto;

  @include device-sm-or-smaller {
    margin: rem-calc(24px) auto 0 auto;
  }
}

.container {
  align-items: end;
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: 1fr 10rem 1fr;
  justify-content: center;

  &.hideLabels {
    grid-template-columns: 1fr;
  }
}

.gauge {
  display: grid;
  height: 5rem;
  place-items: center;
}

.label {
  font-size: 0.875rem;
}

.good {
  text-align: right;
}

.bad {
  text-align: left;
}

$color-needle: #184895;

$animation-transition: 1s all ease;

.needle {
  fill: $color-needle;
  transform-origin: 80px 80px;
  transition: $animation-transition;

  &.unset {
    opacity: 0;
  }
}

.center {
  fill: $color-needle;
}

.gradientStart {
  stop-color: #d1272b;
  stop-opacity: 1;
}

.gradientStop {
  stop-color: $color-needle;
  stop-opacity: 1;
  stroke-width: 0;
}

.gradient {
  stroke-width: 1px;
  stroke: #fff;
}

.overlay {
  fill: #e3e3e8;
  transform-origin: bottom center;
  transition: $animation-transition;
}

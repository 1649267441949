@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

.afccLogo {
  display: block;
  margin: 0 auto;
}

.disclaimer {
  color: $neutrals-gray5;
  font-size: rem-calc(10px);
  line-height: rem-calc(14px);
  margin-bottom: rem-calc(24px);
}

.dot {
  height: rem-calc(5px);
  width: rem-calc(5px);
  background-color: $tertiary-midnight-blue;
  border-radius: 50%;
  display: inline-block;
  margin: rem-calc(1px) rem-calc(8px) rem-calc(2px);
}

.footer {
  max-width: rem-calc(1168px);
  margin: 0 auto;
  padding: rem-calc(20px) rem-calc(16px);
}

.footerlinks {
  display: block;
  margin: rem-calc(24px) auto;
  text-align: center;
  font-weight: 600;
  font-size: rem-calc(12px);
  line-height: rem-calc(16px);
  color: $tertiary-denim-blue;

  @include device-md {
    width: 100%;
  }

  @include device-lg {
    width: 100%;
  }
}

.footerlinks a {
  display: inline-block;
  font-size: rem-calc(11px);
}

.footerlinks p {
  vertical-align: middle;
}

.footer a {
  color: $tertiary-midnight-blue;
  font-size: rem-calc(12px);
  line-height: rem-calc(16px);
  text-decoration: none;
}

.line {
  border-bottom: 1px solid $neutrals-gray3;
  display: block;
  margin-bottom: rem-calc(24px);
}

.wrapper {
  background: $neutrals-gray1;
  display: grid;
  justify-content: center;
  width: 100%;
}

.copyright {
  color: $neutrals-gray5;
  font-size: rem-calc(12px);
  line-height: rem-calc(16px);
}

.rightsReserved {
  margin: 0 auto;
  text-align: center;
  color: $neutrals-gray5;
  font-size: rem-calc(10px);
  line-height: rem-calc(14px);
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;

.container {
  align-items: start;
  display: grid;
  justify-items: center;
  max-width: rem-calc(468px);
  row-gap: rem-calc(40px);
}

.totalContainer {
  display: grid;
  justify-items: center;
  row-gap: rem-calc(4px);
}

.total {
  display: grid;
  font-size: rem-calc(24px);
  font-weight: 700;
  justify-items: center;
  margin: $reset-margin;
}

.subtitle {
  font-size: rem-calc(12px);
  font-weight: normal;
  margin: $reset-margin;
}

.metrics {
  display: flex;
  justify-content: space-evenly;

  @include device-sm-or-smaller {
    flex-flow: wrap;
  }
}

$metric-max-size: rem-calc(116px);
$metric-min-size: rem-calc(96px);

.metric {
  width: $metric-max-size;
  margin: 0 rem-calc(8px) rem-calc(16px);

  @include device-sm-or-smaller {
    max-width: $metric-min-size;
  }

  @include device-xs {
    max-width: $metric-min-size;
  }

  @include device-md-or-larger {
    margin: 0 rem-calc(30px);
  }
}

.legal {
  color: $neutrals-gray5;
  font-size: rem-calc(12px);
  font-weight: 400;
  line-height: rem-calc(16px);
}

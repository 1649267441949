@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.container {
  border-radius: rem-calc(8px);
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  width: 100%;
}

.button {
  background-color: transparent;
  border-radius: rem-calc(8px);
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-align: start;
  align-items: center;
  color: $neutrals-gray5;
  font-size: rem-calc(12px);
  font-weight: bold;
  display: block;
  min-height: rem-calc(24px);
}

.button:focus,
.button:active {
  outline-color: $primary-blue;
}

.moreLess {
  justify-self: end;
}

.spacer {
  height: 1rem;
}

.childContent {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: rem-calc(12px);
  overflow: hidden;
  position: relative;
}

.fader {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(248, 248, 248, 1) 100%);
  height: rem-calc(32px);
  margin-top: rem-calc(-32px);
}

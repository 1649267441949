@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

.inputGroup {
  input {
    outline: none;
  }

  margin-bottom: rem-calc(60px);

  &:first-child {
    margin-top: rem-calc(58px);

    @include device-sm-or-smaller {
      margin-top: rem-calc(34px);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .icon {
    margin-right: rem-calc(16px);
    flex-shrink: 0;
    height: 1rem;
    margin-top: rem-calc(4px);
  }

  .content {
    flex: 1;
  }

  .label {
    font-weight: bold;
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
  }

  .description {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    margin-bottom: rem-calc(28px);
    color: $secondary-gray-light;
    font-style: italic;
  }

  .textField {
    width: 100%;
  }

  .label + .textField {
    margin-top: rem-calc(6px);
  }

  .input fieldset,
  .inputLabel {
    font-size: rem-calc(14px);
  }

  .inputLabel {
    color: $tertiary-blue-gradient-light;
  }

  .input fieldset {
    border-color: $tertiary-blue-gradient-light;
  }

  .input input {
    padding-top: rem-calc(13px);
    padding-bottom: rem-calc(13px);
  }
}

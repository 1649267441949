@import 'styles/scss/utils/rem-calc';

.container {
  align-items: start;
  display: grid;
  justify-items: center;
  height: 100%;
  min-height: rem-calc(180px);
  width: 100%;
}

.region {
  filter: drop-shadow(rem-calc(4px) rem-calc(4px) rem-calc(10px) rgba(0, 0, 0, 0.4));
}

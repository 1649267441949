@import 'styles/scss/variables';
@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';

.detailsTable {
  th {
    text-align: left;
    font-weight: normal;
    color: #6e6e6e;
  }

  td {
    text-align: right;
    font-weight: 600;
  }

  margin: 0;
  font-size: rem-calc(12px);

  td,
  th {
    min-width: rem-calc(159px);
    padding-bottom: rem-calc(12px);
  }

  @include device-sm-or-smaller {
    td,
    th {
      min-width: rem-calc(127px);
    }
  }
}

.actionColorAndFont {
  font-style: normal;
  font-weight: bold;
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);
  color: #184895;
  padding: rem-calc(16px);
}

.someChildInComponent {
  font-weight: bold;
}

.dialogActions {
  justify-content: center;
  padding: 0;
}

.red {
  color: $tertiary-red-dark;
}

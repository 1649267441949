@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;
$reset-padding: 0;

.container {
  align-items: start;
  display: grid;
  list-style: none;
  margin: $reset-margin;
  padding: $reset-padding;
  row-gap: rem-calc(24px);
}

@include device-md-or-larger {
  .container {
    max-width: rem-calc(348px);
  }
}

@include device-lg-or-larger {
  .container {
    max-width: rem-calc(374px);
  }
}

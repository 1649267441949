@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.name,
.amount {
  line-height: rem-calc(24px);
  padding: rem-calc(16px) rem-calc(6px) rem-calc(16px) 0;
  align-items: center;
  border-bottom: 1px solid $neutrals-gray3;
}

.amount {
  padding: rem-calc(16px) 0 rem-calc(16px) rem-calc(6px);
  font-weight: 600;
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';
@import 'styles/scss/utils/responsive';

.container {
  @include device-md-or-smaller {
    p {
      font-size: rem-calc(14px);
    }
  }
}

.headerContainer {
  position: absolute;
  top: 0;
  width: 100vw;
}

.hero {
  align-items: center;
  margin: rem-calc(24px) rem-calc(44px);
  text-align: center;
}

.title {
  font-size: rem-calc(32px);
  font-weight: bold;
  margin-bottom: rem-calc(8px);

  @include device-md-or-smaller {
    font-size: rem-calc(24px);
  }
}

@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

.container {
  align-items: start;
  display: grid;
  justify-items: center;
}

.spacerTop {
  height: rem-calc(32px);
}

.spacerBottom {
  height: rem-calc(40px);
}

@include device-sm-or-larger {
  .spacerTop {
    height: rem-calc(56px);
  }
}

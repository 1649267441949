@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;

.container {
  display: grid;
  justify-items: center;
  row-gap: rem-calc(4px);
}

.title {
  font-size: rem-calc(12px);
  font-weight: normal;
  margin: $reset-margin;
  text-align: center;
}

.content {
  font-size: rem-calc(24px);
  font-weight: 700;
  margin: $reset-margin;
  text-align: center;
}

.footer {
  font-size: rem-calc(12px);
  justify-items: center;
  margin: $reset-margin;
}

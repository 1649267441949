@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/overlay';
@import 'styles/scss/utils/responsive';

.container {
  justify-content: center;
  align-content: flex-start;
  padding-top: rem-calc(95px);
  position: relative;
}

.phone {
  position: absolute;
  top: rem-calc(16px);
  right: rem-calc(16px);
}

.logo-container {
  margin-top: rem-calc(30px);
}

.name {
  font-size: rem-calc(32px);
  font-weight: bold;

  @include device-md-or-smaller {
    font-size: rem-calc(24px);
  }
}

@media (max-width: 1200px) {
  .container {
    padding-top: rem-calc(20px);
  }

  .logo {
    max-width: 200px;
  }
}

.logo {
  width: rem-calc(170px);
  margin: auto;
  justify-self: center;
}

.heroText {
  font-size: rem-calc(12px);
}

.coAppAnd {
  font-size: rem-calc(12px);
  line-height: rem-calc(12px);
  font-weight: normal;
  margin: rem-calc(4px) 0;
}

.explanation {
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  margin-top: rem-calc(95px);
}

.headerContainer {
  position: absolute;
  top: 0;
  width: 100vw;
}

.content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: rem-calc(392px);

  @include device-md-or-smaller {
    width: calc(279px);
  }
}

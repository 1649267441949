@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.root {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr minmax(rem-calc(39px), 15%);
  color: $font-color;
}

.overutilized {
  color: $tertiary-red-dark;
}

@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.root {
  text-align: left;
}

.name,
.amount {
  font-size: rem-calc(16px);
  line-height: rem-calc(24px);
  padding: rem-calc(16px) rem-calc(6px) rem-calc(16px) 0;
  align-items: center;
  border-bottom: 1px solid $neutrals-gray3;
  word-wrap: break-word;
}

.name {
  text-align: left;
}

.amount {
  padding: rem-calc(16px) 0 rem-calc(16px) rem-calc(6px);
  font-weight: 600;
}

.amountIcon {
  fill: $font-color;
  margin-top: rem-calc(6px);
}

.floatRight {
  float: right;
}

.coApplicant {
  padding-top: rem-calc(16px);
  line-height: 0;
}

.hasCoApplicant {
  padding-top: rem-calc(8px);
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  flex-grow: 0;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
}

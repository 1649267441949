@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.container {
  display: grid;
  margin-top: rem-calc(15px);
  row-gap: rem-calc(16px);
  margin-bottom: rem-calc(10px);

  p {
    margin: 0;
    padding: 0;

    :last-child {
      margin-bottom: 0;
    }
  }

  ol {
    display: grid;
    margin: 0;
    margin-inline-start: rem-calc(14px);
    padding: 0;
    row-gap: rem-calc(16px);
  }
}

@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

.container {
  justify-self: center;
  max-width: calc(100vw - #{rem-calc(96px)});

  @include device-xs {
    margin: 0 0 rem-calc(173px) 0;
  }

  @include device-sm-or-larger {
    margin: rem-calc(88px) 0 rem-calc(317px) 0;
    max-width: calc(100vw - #{rem-calc(144px)});
  }

  @include device-lg-or-larger {
    max-width: rem-calc(770px);
  }
}

.stepHeader {
  @include device-xs {
    padding-top: rem-calc(40px);
  }
}

.item {
  margin-top: rem-calc(72px);

  &:first-child {
    margin-top: rem-calc(50px);
  }

  img {
    margin-right: rem-calc(16px);
    flex-shrink: 0;
  }
}

.label {
  font-weight: bold;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  color: #25438e;
  margin-bottom: rem-calc(2px);
}

.text {
  margin-top: rem-calc(-7px);
  max-width: rem-calc(205px);

  @include device-sm-or-larger {
    max-width: rem-calc(286px);
  }
}

@import 'styles/scss/utils/rem-calc';
@import 'styles/scss/utils/responsive';

$reset-margin: 0;
$reset-padding: 0;

.container {
  align-items: start;
  display: grid;
  justify-self: center;
  list-style: none;
  margin: $reset-margin;
  padding: $reset-padding;
  row-gap: rem-calc(16px);

  @include device-lg-or-larger {
    max-width: rem-calc(588px);
  }
}

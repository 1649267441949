@import 'styles/scss/variables';
@import 'styles/scss/utils/responsive';
@import 'styles/scss/utils/rem-calc';

.freedomPhone {
  line-height: 1;

  &:hover {
    opacity: 0.9;
  }
}

.svg,
.number {
  vertical-align: middle;
  display: inline-block;
}

.number {
  padding-left: rem-calc(10px);
  font-weight: 600;

  @include device-sm-or-smaller {
    display: none;
  }
}

.white {
  path {
    fill: $neutrals-pure-white;
  }

  .number {
    color: $neutrals-pure-white;
  }
}

.blue {
  path {
    fill: $logo-blue;
  }

  .number {
    color: $neutrals-gray6;
  }
}

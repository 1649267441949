// Freedom Palette - see https://www.figma.com/file/ypgJfl4CR4CPMMTkuQtNWu/FDR-Lead-Acquisition-v1.1-(Production)
$primary-blue: #184895;

$secondary-orange-light: #f66800;
$secondary-orange-dark: #ff9404;
$secondary-light-blue-light: #009dda;
$secondary-light-blue-dark: #21b0e8;
$secondary-gray-light: #7b7b7b;

$tertiary-midnight-blue: #213246;
$tertiary-denim-blue: #00457c;
$tertiary-green-light: #18764f;
$tertiary-green-dark: #45ae57;
$tertiary-green-light-2: #005128;
$tertiary-red-light: #bd252c;
$tertiary-red-dark: #e7474e;
$tertiary-yellow-light: #ffc100;
$tertiary-yellow-dark: #ffd85f;
$tertiary-blue-gradient-light: #184895;
$tertiary-blue-gradient-dark: #21b0e8;
$tertiary-blue-gradient-light-2: #184895;

$neutrals-pure-white: #fff;
$neutrals-gray1: #f8f8f8;
$neutrals-gray2: #e3e3e8;
$neutrals-gray3: #c7c7cc;
$neutrals-gray4: #a7a7a7;
$neutrals-gray5: #6e6e6e;
$neutrals-gray6: #333;
$neutrals-gray7: #212121;

$font-family: 'Open Sans', sans-serif;
$body-background: $neutrals-pure-white;
$font-color: $neutrals-gray7;
$primary-color: $primary-blue;
$secondary-color: $neutrals-gray1;

$logo-blue: #26438e;
$logo-red: #d1272b;
$black: #000;

// Media queries breakpoints
$screen-xs: 480px;
// 👆 Extra small screen / phone
// 👇 Small screen / tablet
$screen-sm: 600px;
// Medium screen / desktop
$screen-md: 960px;
// Large screen / wide desktop
$screen-lg: 1280px;
// Extra large screen / full hd
$screen-xl: 1920px;

$primary-font: 'Open Sans';

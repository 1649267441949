@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.container {
  place-items: center;
  display: grid;
  row-gap: rem-calc(20px);
}

.progress {
  color: $primary-blue;
}

.dark {
  color: $neutrals-pure-white;
}

@import 'styles/scss/utils/responsive';
@import 'styles/scss/variables';
@import 'styles/scss/utils/rem-calc';

.container {
  display: grid;
  justify-items: center;

  @include device-xs {
    row-gap: rem-calc(24px);
  }
}

.content {
  padding-top: rem-calc(24px);
}

.valuePropSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: rem-calc(24px) 0;
}

.valuePropTitle {
  font-size: 18px;
  font-weight: 600;
}

.valuePropSubtitle {
  font-size: 14px;
  color: $neutrals-gray5;
  padding: rem-calc(6px) 0;
}

.valuePropValue {
  font-size: 24px;
  font-weight: bold;
}

.getStartedButton {
  font-size: 15px;
  font-weight: 600;
  margin-top: 6px;
  padding: 12px 60px;
  background-color: $secondary-light-blue-light;
  color: $neutrals-pure-white;
  text-transform: capitalize;
  border-radius: 10px;
}

.getStartedButton:hover {
  background-color: $secondary-light-blue-dark;
}

.getStartedButtonNoPhone {
  font-size: 18px;
  font-weight: 600;
  margin-top: 6px;
  padding: 12px 60px;
  background-color: $secondary-light-blue-light;
  color: $neutrals-pure-white;
  text-transform: capitalize;
  border-radius: 10px;

  @include device-sm-or-smaller {
    padding: 12px 50px;
  }
}

.getStartedButtonNoPhone:hover {
  background-color: $secondary-light-blue-dark;
}

.arrowIconWrapper {
  display: grid;
  place-items: center;
  background-color: $neutrals-pure-white;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  padding: 2px;
}

.phoneIconWrapper {
  display: grid;
  place-items: center;
  height: 24px;
  width: 24px;
  padding: 2px;
}

.arrowIcon {
  path {
    fill: $secondary-light-blue-light;
  }

  height: 10px;
}

.phoneIcon {
  path {
    fill: $neutrals-pure-white;
  }

  height: 24px;
}

.legalContent {
  margin-top: 30px;
}

.legalFootnotes {
  display: grid;
  margin: 0;
  margin-inline-start: rem-calc(20px);
  padding: 0;
  row-gap: rem-calc(16px);
}
